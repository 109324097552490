@font-face {
    font-family: 'Graphik-700';
    src: url('./components/fonts/Graphik-Bold.otf');
}

@font-face {
    font-family: 'Graphik-400';
    src: url('./components/fonts/Graphik-Regular.otf');
}

@font-face {
    font-family: 'Graphik-300';
    src: url('./components/fonts/Graphik-Light.otf');
}

@font-face {
    font-family: 'Graphik-500';
    src: url('./components/fonts/Graphik-Medium.otf');
}

@font-face {
    font-family: 'Graphik-600';
    src: url('./components/fonts/Graphik-Semibold.otf');
}

@font-face {
    font-family: 'Graphik-300-italic';
    src: url('./components/fonts/Graphik-LightItalic.otf');
}

@font-face {
    font-family: 'Graphik-500-italic';
    src: url('./components/fonts/Graphik-SemiboldItalic.otf');
}

@font-face {
    font-family: 'Graphik-400-italic';
    src: url('./components/fonts/Graphik-RegularItalic.otf');
}

@property --gradient-angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

@keyframes rotation {
    0% {--gradient-angle: 0deg;}
    100% {--gradient-angle: 360deg;}
}

@font-face {
    font-family: 'Berksire';
    src: url('./components/fonts/BerkshireSwash-Regular.ttf');
}

@font-face {
    font-family: 'Logo';
    src: url('./components//fonts/Tephra.otf');
}

@keyframes gradient {
    0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 20% 50%;
	}
}

/* These are to trigger the navigation onscroll */

.top {
    top: 0px;
  }

  .down {
    top: -80px;
  }

  .up {
    top: 0px;
  }

/* This is for the site intro onload */
  .intro{
	position: fixed;
	z-index: 1000;
	left: 0%;
	top: 0%;
	width: 100%;
	height: 100vh;
	background-color: #0f0f25;
	transition: 1s;
}

.logo-header{
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #030304;
}

.logo{
	position: relative;
	display: inline-block;
	bottom: -20px;
	opacity: 0;
    font-family: Logo;
    color: #fff;
    font-size: 3rem;
}

.logo.active{
	bottom: 0;
	opacity: 1;
	transition: ease-in-out 0.5s;
}

.logo.fade{
	bottom: 0;
	opacity: 0;
	transition: ease-in-out 0.5s;
}

@media screen and (max-width: 440px){
    .logo {
        font-size: 2.25rem;
    }
}